// import React, { useState, useEffect, useRef } from "react";
// import { Radio, Table, Breadcrumb, Descriptions, Card, Space } from "antd";
// import {
//   BarChart,
//   Bar,
//   Cell,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   LabelList,
//   ResponsiveContainer,
// } from "recharts";
// import { useMutation } from "@apollo/client";
// import { BurnChats, BurnGeneral, BurnFilter } from "../../conf/mutation";
// import "./BornOut.scss";
// import Comprometido from "./textos/Comprometido";
// import Continuarmonit from "./textos/Continuarmonit";
// import Apaticos from "./textos/Apaticos";
// import Sobrecarga from "./textos/Sobrecarga";
// import Ineficaz from "./textos/Ineficaz";
// import Burnouttext from "./textos/Burnouttext";
// import Fatiga from "./textos/Fatiga";
// import Nocomprometidos from "./textos/Nocomprometidos";
// import { isNil } from "lodash";

// const BornOut = () => {
//   const [Charts] = useMutation(BurnChats);
//   const [General] = useMutation(BurnGeneral);
//   const [Filterburn] = useMutation(BurnFilter);

//   const [DataChart, setDataChart] = useState([]);
//   const [data, setDataGen] = useState([]);
//   const [Tipos, setTipos] = useState(0);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     getChartTotal();
//     getGeneral();
//   }, []);

//   const getGeneral = async () => {
//     const { data } = await General({
//       variables: {
//         input: { EMP: "43", ENC: "1" },
//       },
//     });
//     const datos = JSON.parse(data.burnOutGeneral.mensaje);
//     setDataGen(datos);
//     console.log(datos);
//   };
//   const getChartTotal = async () => {
//     const { data } = await Charts({
//       variables: {
//         input: { EMP: "43", ENC: "1" },
//       },
//     });

//     const da = JSON.parse(data.burnChart.mensaje);

//     const dataChart = da.respuesta.map((item) => ({
//       name: item.f0,
//       punteo: parseInt(item.f1, 10),
//     }));
//     setDataChart(dataChart);
//     setIsLoading(false);
//   };
//   console.log("tipo", Tipos);
//   const btnfiltro = async (valor) => {
//     if (valor == "Comprometido") {
//       setTipos(1);
//     } else if (valor == "Continuar monitoreando") {
//       setTipos(2);
//     } else if (valor == "Ineficaz en el trabajo") {
//       setTipos(3);
//     } else if (valor == "Sobrecargado") {
//       setTipos(4);
//     } else if (valor == "No Comprometido") {
//       setTipos(5);
//     } else if (valor == "Fatiga Social") {
//       setTipos(6);
//     } else if (valor == "Apático") {
//       setTipos(7);
//     } else if (valor == "quet") {
//       setTipos(8);
//     } else if (valor == "Burnout") {
//       setTipos(9);
//     } else if (valor == "") {
//       setTipos(0);
//     }

//     const { data } = await Filterburn({
//       variables: {
//         input: { EMP: "43", ENC: "1", FILTER: valor },
//       },
//     });
//     setDataGen([]);
//     const datos = JSON.parse(data.burnOutGenFilter.mensaje);
//     setDataGen(datos);
//     console.log(datos);
//   };

//   const columns = [
//     {
//       title: "Área",
//       dataIndex: "f0",
//       key: "f0",
//     },
//     {
//       title: "Responsable",
//       dataIndex: "f1",
//       key: "f1",
//     },
//     {
//       title: "Nombre",
//       dataIndex: "f2",
//       key: "f2",
//     },
//     {
//       title: "Estado",
//       dataIndex: "f3",
//       key: "f3",
//     },
//   ];
//   const datac = [
//     {
//       name: "Page A",

//       fv: 2400,
//     },
//   ];

//   return (
//     <div>
//       <div>
//         <div className="headline-2 mb-3">
//           <Breadcrumb
//             style={{ fontSize: 20, color: "black" }}
//             items={[
//               {
//                 href: "/",
//                 title: (
//                   <a
//                     href="#"
//                     style={{ color: "black", textDecoration: "none" }}
//                     onClick={(e) => {
//                       e.preventDefault();
//                       window.location.reload();
//                     }}
//                     onMouseEnter={(e) => (e.target.style.color = "#FF8F00")}
//                     onMouseLeave={(e) => (e.target.style.color = "black")}
//                   >
//                     Inicio
//                   </a>
//                 ),
//               },
//               {
//                 href: "",

//                 title: (
//                   <span
//                     style={{ color: "black", textDecoration: "none" }}
//                     onMouseEnter={(e) => (e.target.style.color = "#FF8F00")}
//                     onMouseLeave={(e) => (e.target.style.color = "black")}
//                     onClick={(e) => {
//                       e.preventDefault();
//                       window.location.reload();
//                     }}
//                   >
//                     Burnout
//                   </span>
//                 ),
//               },
//             ]}
//           />
//         </div>
//         <Radio.Group>
//           <Radio.Button
//             onClick={() => btnfiltro("Comprometido")}
//             value="compro"
//           >
//             Comprometido
//           </Radio.Button>
//           <Radio.Button
//             onClick={() => btnfiltro("Continuar monitoreando")}
//             value="contmoni"
//           >
//             Continuar Monitoreando
//           </Radio.Button>
//           <Radio.Button
//             onClick={() => btnfiltro("Ineficaz en el trabajo")}
//             value="ine"
//           >
//             Ineficaz
//           </Radio.Button>
//           <Radio.Button onClick={() => btnfiltro("Sobrecargado")} value="sobre">
//             Sobrecargado
//           </Radio.Button>
//           <Radio.Button
//             onClick={() => btnfiltro("No Comprometido")}
//             value="nocompro"
//           >
//             No comprometido
//           </Radio.Button>
//           <Radio.Button
//             onClick={() => btnfiltro("Fatiga Social")}
//             value="fatiga"
//           >
//             Fatiga social
//           </Radio.Button>
//           <Radio.Button onClick={() => btnfiltro("Apático")} value="apatico">
//             Apático
//           </Radio.Button>
//           <Radio.Button onClick={() => btnfiltro("quet")} value="quet">
//             Quet Quitting
//           </Radio.Button>
//           <Radio.Button onClick={() => btnfiltro("Burnout")} value="Born">
//             Burnout
//           </Radio.Button>
//         </Radio.Group>

//         <div className="row" style={{ height: 600 }}>
//           <div className="colback shadow-sm">
//             <div className="row">
//               <div className="col-md-4">
//                 <Table
//                   columns={columns}
//                   dataSource={data.respuesta}
//                   scroll={{ x: "max-content", y: 400 }}
//                   pagination={false}
//                 />
//               </div>
//               <div className="col-md-8">
//                 <div>
//                   {isLoading ? (
//                     <p>Cargando datos...</p>
//                   ) : Tipos === 1 ? (
//                     <Comprometido />
//                   ) : Tipos === 2 ? (
//                     <Continuarmonit />
//                   ) : Tipos === 3 ? (
//                     <Ineficaz />
//                   ) : Tipos === 4 ? (
//                     <Sobrecarga />
//                   ) : Tipos === 5 ? (
//                     <Nocomprometidos />
//                   ) : Tipos === 6 ? (
//                     <Fatiga />
//                   ) : Tipos === 7 ? (
//                     <Apaticos />
//                   ) : Tipos === 8 ? (
//                     <h1 />
//                   ) : Tipos === 9 ? (
//                     <Burnouttext />
//                   ) : Tipos === 0 || isNil(Tipos) ? (
//                     <div style={{ width: "100%", height: "500px" }}>
//                       {" "}
//                       {/* Asegúrate de que haya un tamaño definido */}
//                       <ResponsiveContainer width="100%" height="100%">
//                         <BarChart
//                           data={DataChart}
//                           margin={{
//                             top: 0,
//                             right: 30,
//                             left: 20,
//                             bottom: 50,
//                           }}
//                         >
//                           <CartesianGrid strokeDasharray="3 3" />
//                           <XAxis dataKey="name" angle={-15} textAnchor="end" />
//                           <YAxis />
//                           <Tooltip />
//                           <Legend
//                             layout="horizontal"
//                             align="right"
//                             verticalAlign="top"
//                             iconType="circle"
//                             wrapperStyle={{
//                               fontSize: "14px",
//                               fontWeight: "bold",
//                               color: "#333",
//                             }}
//                           />
//                           <Bar dataKey="punteo" fill="#FF8F00" minPointSize={1}>
//                             <LabelList dataKey="punteo" position="top" />
//                           </Bar>
//                         </BarChart>
//                       </ResponsiveContainer>
//                     </div>
//                   ) : null}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BornOut;
import React, { useState, useEffect } from "react";
import { Radio, Table, Breadcrumb } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { useMutation } from "@apollo/client";
import { BurnChats, BurnGeneral, BurnFilter } from "../../conf/mutation";
import "./BornOut.scss";
import Comprometido from "./textos/Comprometido";
import Continuarmonit from "./textos/Continuarmonit";
import Apaticos from "./textos/Apaticos";
import Sobrecarga from "./textos/Sobrecarga";
import Ineficaz from "./textos/Ineficaz";
import Burnouttext from "./textos/Burnouttext";
import Fatiga from "./textos/Fatiga";
import Nocomprometidos from "./textos/Nocomprometidos";
import { isNil } from "lodash";

const BornOut = () => {
  const [Charts] = useMutation(BurnChats);
  const [General] = useMutation(BurnGeneral);
  const [Filterburn] = useMutation(BurnFilter);

  const [DataChart, setDataChart] = useState([]);
  const [data, setDataGen] = useState([]);
  const [Tipos, setTipos] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [hasData, setHasData] = useState(false);
  const [empresa, setEmpresa] = useState(null);
  const [encno, setEncno] = useState(null);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("dataBurn"));
    if (storedData && storedData.respuesta && storedData.respuesta.length > 0) {
      setHasData(true);
      setEmpresa(storedData.respuesta[0].EMPRESA);
      setEncno(storedData.respuesta[0].ENCNO);
      getChartTotal(
        storedData.respuesta[0].EMPRESA,
        storedData.respuesta[0].ENCNO
      );
      getGeneral(
        storedData.respuesta[0].EMPRESA,
        storedData.respuesta[0].ENCNO
      );
    } else {
      setHasData(false);
    }
  }, []);

  const getGeneral = async (empresa, encno) => {
    const { data } = await General({
      variables: {
        input: { EMP: empresa, ENC: encno },
      },
    });
    const datos = JSON.parse(data.burnOutGeneral.mensaje);
    setDataGen(datos);
    console.log(datos);
  };

  const getChartTotal = async (empresa, encno) => {
    const { data } = await Charts({
      variables: {
        input: { EMP: empresa, ENC: encno },
      },
    });

    const da = JSON.parse(data.burnChart.mensaje);
    const dataChart = da.respuesta.map((item) => ({
      name: item.f0,
      Personas: parseInt(item.f1, 10),
    }));
    setDataChart(dataChart);
    setIsLoading(false);
  };

  const btnfiltro = async (valor) => {
    if (valor === "Comprometido") {
      setTipos(1);
    } else if (valor === "Continuar monitoreando") {
      setTipos(2);
    } else if (valor === "Ineficaz en el trabajo") {
      setTipos(3);
    } else if (valor === "Sobrecargado") {
      setTipos(4);
    } else if (valor === "No Comprometido") {
      setTipos(5);
    } else if (valor === "Fatiga Social") {
      setTipos(6);
    } else if (valor === "Apático") {
      setTipos(7);
    } else if (valor === "quet") {
      setTipos(8);
    } else if (valor === "Burnout") {
      setTipos(9);
    } else if (valor === "") {
      setTipos(0);
    }

    const { data } = await Filterburn({
      variables: {
        input: { EMP: empresa, ENC: encno, FILTER: valor },
      },
    });
    setDataGen([]);
    const datos = JSON.parse(data.burnOutGenFilter.mensaje);
    setDataGen(datos);
    console.log(datos);
  };

  const columns = [
    { title: "Área", dataIndex: "f0", key: "f0" },
    { title: "Responsable", dataIndex: "f1", key: "f1" },
    { title: "Nombre", dataIndex: "f2", key: "f2" },
    { title: "Estado", dataIndex: "f3", key: "f3" },
  ];

  return (
    <div>
      {hasData ? (
        <div>
          <div className="headline-2 mb-3">
            <Breadcrumb
              style={{ fontSize: 20, color: "black" }}
              items={[
                {
                  href: "/",
                  title: (
                    <a
                      href="#"
                      style={{ color: "black", textDecoration: "none" }}
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.reload();
                      }}
                      onMouseEnter={(e) => (e.target.style.color = "#FF8F00")}
                      onMouseLeave={(e) => (e.target.style.color = "black")}
                    >
                      Inicio
                    </a>
                  ),
                },
                {
                  href: "",
                  title: (
                    <span
                      style={{ color: "black", textDecoration: "none" }}
                      onMouseEnter={(e) => (e.target.style.color = "#FF8F00")}
                      onMouseLeave={(e) => (e.target.style.color = "black")}
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.reload();
                      }}
                    >
                      Burnout
                    </span>
                  ),
                },
              ]}
            />
          </div>
          <Radio.Group>
            <Radio.Button
              onClick={() => btnfiltro("Comprometido")}
              value="compro"
            >
              Comprometido
            </Radio.Button>
            <Radio.Button
              onClick={() => btnfiltro("Continuar monitoreando")}
              value="contmoni"
            >
              Continuar Monitoreando
            </Radio.Button>
            <Radio.Button
              onClick={() => btnfiltro("Ineficaz en el trabajo")}
              value="ine"
            >
              Ineficaz
            </Radio.Button>
            <Radio.Button
              onClick={() => btnfiltro("Sobrecargado")}
              value="sobre"
            >
              Sobrecargado
            </Radio.Button>
            <Radio.Button
              onClick={() => btnfiltro("No Comprometido")}
              value="nocompro"
            >
              No comprometido
            </Radio.Button>
            <Radio.Button
              onClick={() => btnfiltro("Fatiga Social")}
              value="fatiga"
            >
              Fatiga social
            </Radio.Button>
            <Radio.Button onClick={() => btnfiltro("Apático")} value="apatico">
              Apático
            </Radio.Button>
            <Radio.Button onClick={() => btnfiltro("quet")} value="quet">
              Quet Quitting
            </Radio.Button>
            <Radio.Button onClick={() => btnfiltro("Burnout")} value="Born">
              Burnout
            </Radio.Button>
          </Radio.Group>

          <div className="row" style={{ height: 600 }}>
            <div className="colback shadow-sm">
              <div className="row">
                <div className="col-md-4">
                  <Table
                    columns={columns}
                    dataSource={data.respuesta}
                    scroll={{ x: "max-content", y: 400 }}
                    pagination={false}
                  />
                </div>
                <div className="col-md-8">
                  <div>
                    {isLoading ? (
                      <p>Cargando datos...</p>
                    ) : Tipos === 1 ? (
                      <Comprometido />
                    ) : Tipos === 2 ? (
                      <Continuarmonit />
                    ) : Tipos === 3 ? (
                      <Ineficaz />
                    ) : Tipos === 4 ? (
                      <Sobrecarga />
                    ) : Tipos === 5 ? (
                      <Nocomprometidos />
                    ) : Tipos === 6 ? (
                      <Fatiga />
                    ) : Tipos === 7 ? (
                      <Apaticos />
                    ) : Tipos === 8 ? (
                      <h1 />
                    ) : Tipos === 9 ? (
                      <Burnouttext />
                    ) : Tipos === 0 || isNil(Tipos) ? (
                      <div style={{ width: "100%", height: "500px" }}>
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart
                            data={DataChart}
                            margin={{ top: 0, right: 30, left: 20, bottom: 50 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="name"
                              angle={-15}
                              textAnchor="end"
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend
                              layout="horizontal"
                              align="right"
                              verticalAlign="top"
                              iconType="circle"
                              wrapperStyle={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#333",
                              }}
                            />
                            <Bar
                              dataKey="Personas"
                              fill="#FF8F00"
                              minPointSize={1}
                            >
                              <LabelList dataKey="Personas" position="top" />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1>No cuentas con encuesta burnout</h1>
      )}
    </div>
  );
};

export default BornOut;
